<template>
  <div>
    <Chips
      :id="id"
      :value="modelValue"
      :placeholder="placeholder"
  
       separator=","
      :allowDuplicate="false"
      :aria-describedby="`${id}-help`"
      @input="$emit('update:modelValue', $event.target.value)"
    />
<span
      v-if="
        validation?.$error &&
        validation.required &&
        validation.required.$invalid
      "
      :id="`${id}-help`"
      class="p-error text-left mt-2"
    >
      {{ validation.required.$message.replace("Value", PropName) }}
    </span>
    <span
      v-else-if="
        validation?.$error &&
        validation.requiredIf &&
        validation.requiredIf.$invalid
      "
      :id="`${id}-help`"
      class="p-error text-left mt-2"
    >
      {{ validation.requiredIf.$message.replace("value", PropName) }}
    </span> 
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: "",
    },
    PropName: {
      type: String,
      default: "",
    },
    separator: {
      type: String,
      default: "",
    },
    validation: {
      type: Object,
      required: true,
    },
    modelstate: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    modelValue: {
      type: String,
      default: "",
    },
  },
};
</script>
<style lang="scss" scoped>
:deep(.p-chips:not(.p-disabled).p-focus .p-chips-multiple-container){
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none !important;
  border: #c0c0bc !important;
  outline: 2px solid #222222 !important;
  border-style: double !important;
}
</style>
