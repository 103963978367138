<template>
  <div>
    <InputNumber
      :id="id"
      class="w-full"
      :modelValue="modelValueComputed"
      :disabled="disabled"
      :placeholder="placeholder"
      :min="min"
      :max="max"
      :useGrouping="useGrouping"
      :class="{
        'p-invalid': validation?.$error,
      }"
      :aria-describedby="`${id}-help`"
      @input="$emit('update:modelValue', $event.value === null ? 0 : $event.value)"
    />
    <span
      v-if="
        validation?.$error &&
        validation.required &&
        validation.required.$invalid
      "
      :id="`${id}-help`"
      class="p-error text-left mt-2"
    >
      {{ validation.required.$message.replace("Value", PropName) }}
    </span>
    <span
      v-if="
        validation?.$error && validation.integer && validation.integer.$invalid
      "
      :id="`${id}-help`"
      class="p-error text-left mt-2"
    >
      {{ validation.integer.$message.replace("Value", PropName) }}
    </span>
    <span
      v-if="
        validation?.$error &&
        validation.minValue &&
        validation.minValue.$invalid
      "
      :id="`${id}-help`"
      class="p-error text-left mt-2"
    >
      {{ validation.minValue.$message.replace("Value", PropName) }}
    </span>
    <span
      v-if="
        validation?.$error &&
        validation.maxValue &&
        validation.maxValue.$invalid
      "
      :id="`${id}-help`"
      class="p-error text-left mt-2"
    >
      {{ validation.maxValue.$message.replace("Value", PropName) }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    labelClass: {
      type: String,
      default: "form-label",
    },
    PropName: {
      type: String,
      default: "",
    },
    sectionClass: {
      type: String,
      default: "field",
    },
    id: {
      type: String,
      required: true,
      default: "",
    },
    label: {
      type: String,
      required: false,
      default: "",
    },
    validation: {
      type: Object,
      required: false,
    },
    min: {
      type: Number,
      default: null,
    },
    max: {
      type: Number,
      default: null,
    },
    modelValue: {
      type: Number,
      default: 0,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    useGrouping: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    modelValueComputed: {
      get() {
        return this.modelValue === null ? 0 : this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
:deep(.p-inputtext:enabled:focus) {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none !important;
  border: #c0c0bc !important;
  outline: 2px solid #222222 !important;
  border-style: double !important;
}
</style>
