// import Vue from "vue";
import axios from "axios";
import Store from "../store/index";
// import auth from "../app/auth";

const baseURL = Store.state.apiURL;

export default {
  async get(url) {
    Store.commit("showLoader");
    // var accessToken = auth.auth.signInUserSession.idToken.jwtToken;
    var accessToken = Store.state.cognitoInfo.token;
    var jwtHeader = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        CompanyId:
          Store.state.cognitoInfo.companyid == undefined ||
          Store.state.cognitoInfo.companyid == null ||
          Store.state.cognitoInfo.companyid == "undefined" ||
          Store.state.cognitoInfo.companyid == "null"
            ? ""
            : Store.state.cognitoInfo.companyid,
        crossDomain: true,
      },
    };
    return await axios
      .get(baseURL + url, jwtHeader)
      .then((res) => {
        Store.commit("hideLoader");
        return res.data;
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          // Vue.prototype.$alert(
          //   "Error|Something went wrong! Please try after sometime."
          // );
          // this.$router.push({name: "Login"});
          window.location.href = "/login";
        } else {
          this.$toast.add({
            severity: "danger",
            summary: "Error",
            detail: "Error|Something went wrong! Please try after sometime",
            life: 3000,
          });
        }
        Store.commit("hideLoader");
        return error;
      });
  },

  async getById(url, id, param = null, param1 = null, showLoader = true) {
    if (showLoader) Store.commit("showLoader");
    var getURL = baseURL + url + "/" + id;
    if (param != null) {
      getURL += "/" + param;
    }
    if (param1 != null && param1 != "export") {
      getURL += "/" + param1;
    }
    // var accessToken = auth.auth.signInUserSession.idToken.jwtToken;
    var accessToken = Store.state.cognitoInfo.token;
    var jwtHeader = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        CompanyId:
          Store.state.cognitoInfo.companyid == undefined ||
          Store.state.cognitoInfo.companyid == null ||
          Store.state.cognitoInfo.companyid == "undefined" ||
          Store.state.cognitoInfo.companyid == "null"
            ? ""
            : Store.state.cognitoInfo.companyid,
        crossDomain: true,
        "content-type": "application/x-www-form-urlencoded",
      },
    };
    return await axios
      .get(getURL, jwtHeader)
      .then((res) => {
        if (showLoader) Store.commit("hideLoader");
        return res.data;
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          // Vue.prototype.$alert(
          //   "Error|Something went wrong! Please try after sometime."
          // );
          // this.$router.push({name: "Login"});
          window.location.href = "/login";
        }
        // Vue.prototype.$alert(
        //   "Error|Something went wrong! Please try after sometime."
        // );
        else {
          this.$toast.add({
            severity: "danger",
            summary: "Error",
            detail: "Error|Something went wrong! Please try after sometime",
            life: 3000,
          });
        }
        if (showLoader) Store.commit("hideLoader");
        return error;
      });
  },
  async post(url, model, loader = true) {
    if (loader) Store.commit("showLoader");
    // var accessToken = auth.auth.signInUserSession.idToken.jwtToken;
    var accessToken = Store.state.cognitoInfo.token;
    var jwtHeader = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        CompanyId:
          Store.state.cognitoInfo.companyid == undefined ||
          Store.state.cognitoInfo.companyid == null ||
          Store.state.cognitoInfo.companyid == "undefined" ||
          Store.state.cognitoInfo.companyid == "null"
            ? ""
            : Store.state.cognitoInfo.companyid,
        crossDomain: true,
        timeout: 200000,
      },
    };
    return await axios
      .post(baseURL + url, model, jwtHeader)
      .then((res) => {
        if (loader) Store.commit("hideLoader");
        return res.data;
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          // Vue.prototype.$alert(
          //   "Error|Something went wrong! Please try after sometime."
          // );
          // this.$router.push({name: "Login"});
          window.location.href = "/login";
        }
        // Vue.prototype.$alert(
        //   "Error|Something went wrong! Please try after sometime."
        // );
        // this.$toast.add({
        //   severity: "danger",
        //   summary: "Error",
        //   detail: "Error|Something went wrong! Please try after sometime",
        //   life: 3000,
        // });
        if (loader) Store.commit("hideLoader");
        return error;
      })
      .finally(() => {
        if (loader) Store.commit("hideLoader");
      });
  },
  async delete(url, id, param = null) {
    Store.commit("showLoader");
    var deleteURL = baseURL + url + "/" + id;
    if (param != null) {
      deleteURL += "/" + param;
    }
    // var accessToken = auth.auth.signInUserSession.idToken.jwtToken;
    var accessToken = Store.state.cognitoInfo.token;
    var jwtHeader = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        CompanyId:
          Store.state.cognitoInfo.companyid == undefined ||
          Store.state.cognitoInfo.companyid == null ||
          Store.state.cognitoInfo.companyid == "undefined" ||
          Store.state.cognitoInfo.companyid == "null"
            ? ""
            : Store.state.cognitoInfo.companyid,
        crossDomain: true,
      },
    };
    return await axios
      .delete(deleteURL, jwtHeader)
      .then((res) => {
        Store.commit("hideLoader");
        return res.data;
      })
      .catch((error) => {
        // Vue.prototype.$alert(
        //   "Error|Something went wrong! Please try after sometime."
        // );
        if (error.response && error.response.status === 401) {
          // Vue.prototype.$alert(
          //   "Error|Something went wrong! Please try after sometime."
          // );
          // this.$router.push({name: "Login"});
          window.location.href = "/login";
        } else {
          this.$toast.add({
            severity: "danger",
            summary: "Error",
            detail: "Error|Something went wrong! Please try after sometime",
            life: 3000,
          });
        }
        Store.commit("hideLoader");
        return error;
      });
  },
};
