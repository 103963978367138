<template>
  <div class="input-group">
    <Textarea
      :id="id"
      :value="modelValue"
      :placeholder="placeholder"
      :class="{
        'p-invalid': validation?.$error,
      }"
    
      :aria-describedby="`${id}-help`"
      @input="$emit('update:modelValue', $event.target.value)"
    ></Textarea>
    <i class="fa fa-info-circle input-group-icon" v-if="$store.state.cognitoInfo.role == 'Superadmin'"></i>
    <span
      v-if="
        validation?.$error &&
        validation.required &&
        validation.required.$invalid
      "
      :id="`${id}-help`"
      class="p-error text-left mt-2"
    >
      {{ validation.required.$message.replace("Value", PropName) }}
    </span>
    <span
      v-else-if="
        validation?.$error &&
        validation.requiredIf &&
        validation.requiredIf.$invalid
      "
      :id="`${id}-help`"
      class="p-error text-left mt-2"
    >
      {{ validation.requiredIf.$message.replace("value", PropName) }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: "",
    },

    validation: {
      type: Object,
      required: false,
      requiredIf: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    maxlength: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    PropName: {
      type: String,
      default: "",
    },
    modelValue: {
      type: String,
      default: "",
    },
  },
  //   computed: {
  //     name: {
  //       get() {
  //         return this.value;
  //       },
  //       set(value) {
  //         this.$emit("input", value);
  //       },
  //     },
  //   },
};
</script>
<style lang="scss" scoped>
.input-group {
  position: relative;
  display: flex;
  align-items: center;
}
.input-group-icon {
  position: absolute;
  right: 10px; /* Adjust the position as needed */
  pointer-events: none; /* Ensures the icon does not block textarea clicks */
}
.p-inputtext:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none !important;
  border: #c0c0bc !important;
  outline: 2px solid #222222 !important;
  border-style: double !important;
}
</style>
