/* eslint-disable vue/multi-word-component-names */

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store';
import PrimeVue from 'primevue/config'
import { library } from "@fortawesome/fontawesome-svg-core";
import { faUserSecret } from "@fortawesome/free-solid-svg-icons";
import { faFontAwesome } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";


library.add(faUserSecret);
library.add(faFontAwesome);


import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import InputRequiredText from '@/components/elements/InputRequiredText.vue';
import InputRequiredNumber from '@/components/elements/InputRequiredNumber.vue'
import InputRequiredTextArea from '@/components/elements/InputRequiredTextArea.vue'
import InputTextGroup from '@/components/elements/InputTextGroup.vue'
import InputChips from '@/components/elements/input-chips.vue'
import DropDownRequire from '@/components/elements/drop-down.vue';
import InputDate from '@/components/elements/input-date.vue'
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Dropdown from 'primevue/dropdown';
import Calendar from 'primevue/calendar';
import Checkbox from 'primevue/checkbox';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import SplitButton from 'primevue/splitbutton';
import moment from 'moment-timezone';
import ConfirmationService from 'primevue/confirmationservice';
import ConfirmPopup from '@/components/modal-popup/confirm-popup.vue';
import MultiSelect from 'primevue/multiselect';
import Tooltip from 'primevue/tooltip';
import Chart from 'primevue/chart';
import ToastService from 'primevue/toastservice';
import Toast from 'primevue/toast';
import Chip from 'primevue/chip';
import ProgressBar from 'primevue/progressbar';
import Chips from 'primevue/chips';
import FileUpload from 'primevue/fileupload';
import Textarea from 'primevue/textarea';
import RadioButton from 'primevue/radiobutton';
import Slider from 'primevue/slider';
import VueQRCodeComponent from 'vue-qrcode-component'
import VueClipboard from 'vue3-clipboard'
import ColorPicker from 'primevue/colorpicker';
import ProgressSpinner from "primevue/progressspinner";
import Breadcrumb from 'primevue/breadcrumb';
import ToggleButton from 'primevue/togglebutton';
import clickOutside from "@/components/directive/useDetectOutsideClick";

window.$ = window.jQuery = require("jquery");

import 'primevue/resources/themes/bootstrap4-light-blue/theme.css'       //theme
import 'primevue/resources/primevue.min.css'                 //core css
import 'primeflex/primeflex.min.css'
import 'primeicons/primeicons.css'                           //icons
import '@/assets/css/global.css'
import "@/assets/css/font-awesome.min.css";
import "vue-search-select/dist/VueSearchSelect.css";

const app = createApp(App)
app.use(store)
app.use(PrimeVue)
app.use(moment)
app.use(ToastService);
app.use(VueClipboard, {
    autoSetContainer: true,
    appendToBody: true,
  })

app.component("font-awesome-icon", FontAwesomeIcon)

app.component('PrimeButton', Button)
app.component('InputText', InputText)
app.component('InputNumber', InputNumber)
app.component('InputRequiredText', InputRequiredText)
app.component('InputRequiredNumber',InputRequiredNumber)
app.component('InputRequiredTextArea',InputRequiredTextArea)
app.component('InputTextGroup',InputTextGroup)
app.component('InputChips', InputChips)
app.component('DropDownRequire', DropDownRequire)
app.component('InputDate',InputDate)
app.component('DataTable', DataTable)
app.component('Column', Column)
app.component('Dropdown', Dropdown)
app.component('Calendar', Calendar)
app.component('Checkbox', Checkbox)
app.component('TabView', TabView)
app.component('TabPanel', TabPanel)
app.component('SplitButton', SplitButton)
app.component('ConfirmationService', ConfirmationService)
app.component('ConfirmPopup', ConfirmPopup)
app.component('MultiSelect', MultiSelect)
app.component('Chart', Chart)
app.directive('tooltip', Tooltip)
app.directive('clickOutside', clickOutside)

app.component('PrimeToast', Toast)
app.component('Chip', Chip)
app.component('ProgressBar', ProgressBar)
app.component('Chips',Chips)
app.component('FileUpload', FileUpload)
app.component('Textarea',Textarea)
app.component('RadioButton',RadioButton)
app.component('Slider',Slider)
app.component('qr-code', VueQRCodeComponent)
app.component('ColorPicker', ColorPicker)
app.component("ProgressSpinner", ProgressSpinner);
app.component("Breadcrumb", Breadcrumb);
app.component("ToggleButton", ToggleButton);

app.use(router).mount('#app')
