<template>
  <div>
    <Dropdown
      :id="id"
      :modelValue="modelValue"
      :options="options"
      :editable="editable"
      :optionLabel="optionLabel"
      :optionValue="optionValue"
      :optionDisabled="optionDisabled"
      :dropdownIcon="dropdownIcon"
      :placeholder="placeholder"
      :disabled="disabled"
      class="w-full"
      :class="{
        'p-invalid': validation?.$error,
      }"
      :aria-describedby="`${id}-help`"
      @change="$emit('update:modelValue', $event.value)"
    >
      <template #value="slotProps">
        <slot
          name="value"
          :value="slotProps.value"
          :placeholder="slotProps.placeholder"
        ></slot>
      </template>
      <template #option="slotProps">
        <slot name="option" :option="slotProps.option"></slot>
      </template>
    </Dropdown>
    <span
      v-if="validation?.$error && validation?.required?.$invalid"
      :id="`${id}-help`"
      class="p-error text-left mt-2"
    >
      {{ PropName }} is required.
    </span>
    <span
      v-else-if="
        validation?.$error &&
        validation?.requiredIf &&
        validation?.requiredIf.$invalid
      "
      :id="`${id}-help`"
      class="p-error text-left mt-2"
    >
      {{ validation.requiredIf.$message.replace("value", PropName) }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: "",
    },
    sectionClass: {
      type: String,
      default: "field",
    },
    id: {
      type: String,
      required: true,
      default: "",
    },
    dropdownIcon: {
      type: String,
      default: "pi pi-chevron-down",
    },
    validation: {
      type: Object,
      required: false,
    },
    options: {
      type: Array,
      required: false,
    },
    editable: {
      type: String,
      required: false,
    },
    modelValue: {
      type: [String, Number, Object],
      default: "",
    },
    optionLabel: {
      type: String,
      default: "",
    },
    optionValue: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    optionDisabled: {
      type: Function,
      default: null,
    },
    PropName: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss" scoped>
.p-dropdown:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
  border: #c0c0bc !important;
  outline: 2px solid #222222 !important;
  border-style: double !important;
}
</style>
