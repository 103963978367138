<template>
  <div>
    <div class="card spinner" v-if="$store.state.loader > 0">
      <ProgressSpinner />
    </div>
    <section id="container">
      <HeaderSideBar
        :is_not_expand="is_not_expand"
        @call="expand()"
        v-if="show_sidebar"
      />
      <div
      :class="getClassName()"
      >
        <router-view></router-view>
      </div>
      <PrimeToast />
      <section
        id="main-content"
        v-if="
          $route.name !== 'Login' &&
          $route.name !== 'ForgotPassword' &&
          $route.name !== 'ResetPassword' &&
          $route.name !== 'ChangePassword' &&
          $route.name !== 'ManageDealUpdateById' &&
          $route.name !== 'ManageDealUpdateCreate' &&
          $route.name !== 'copydeal' &&
          showFooter
        "
      >
        <footer class="site-footer">
          <div class="text-center">
            {{ new Date().getFullYear() }} &copy; Voyado AB
          </div>
        </footer>
      </section>
      <section
        id="footer-change"
        v-if="
          ($route.name == 'ManageDealUpdateById' ||
            $route.name == 'ManageDealUpdateCreate') &&
          showFooter
        "
      >
        <footer class="site-footer">
          <div class="text-center">
            {{ new Date().getFullYear() }} &copy; Voyado AB
          </div>
        </footer>
      </section>
    </section>
    <PrimeToast />
  </div>
</template>
<script>
import HeaderSideBar from "@/components/layout/HeaderSidebarNew.vue";

export default {
  components: {
    HeaderSideBar,
  },
  data() {
    return {
      is_not_expand: false,
      showParam: null,
      // shouldShowButton: true,
      changeRef: false,
      show_sidebar: true,
      showFooter: false,
    };
  },
  watch: {
    "$store.state.loader": {
      handler(newValue) {
        document.querySelector("body").style.overflow =
          newValue > 0 ? "hidden" : "auto";
      },
      immediate: true,
    },
    $route() {
      this.changeRef = false;
    },
  },
  mounted() {
    window.addEventListener('error', (e) => {
      if (e.message.includes("404")) {
        this.$router.push({ name: "Login" }); // or redirect to login
      }
    });
  },
  
  updated() {
    if (
      this.$route.name !== undefined &&
      this.$route.name !== null &&
      this.$route.name !== "" &&
      this.changeRef == false
    ) {
      const queryParams = new URLSearchParams(window.location.search);
      const showParam = queryParams.get("show");
      const hideMenu = queryParams.get("hide");
      const newQuery = {};

      queryParams.forEach((value, key) => {
        newQuery[key] = value;
      });

      if (showParam === "deallistnew") {
        this.$router.push({ name: "ManageDealList", query: newQuery });
        this.expand();
        // this.shouldShowButton = false;
        this.is_not_expand = true;
        this.show_sidebar = false
      } else if (showParam === "deallist" || showParam === "deallis") {
        if (this.$route.name !== "ManageDealListNew") {
          this.$router.push({
            name: "ManageDealListNew",
            query: newQuery,
          });
          this.is_not_expand = true;
          this.expand();
          // this.shouldShowButton = false;
          this.show_sidebar = false
        } else {
          this.is_not_expand = true;
          this.expand();
          // this.shouldShowButton = false;
          this.show_sidebar = false
        }
      } else if (hideMenu === "menu") {
        this.expand();
        // this.shouldShowButton = false;
        this.is_not_expand = false;
        this.show_sidebar = false;
      } 
      else {
        if (this.$route.name == "Login" ||
        this.$route.name == "ForgotPassword" ||
        this.$route.name == "ResetPassword" ||
        this.$route.name == "ChangePassword" ||
        this.$route.name == "copydeal") {
          this.show_sidebar = false
        this.is_not_expand = false;
        } else {
        this.show_sidebar = true
        this.is_not_expand = false;
        }
      }
      // this.show_sidebar = true;
      // if (!this.is_not_expand) {
      //   this.show_sidebar = true;
      // } else {
      //   this.show_sidebar = false;
      // }
      if (
        this.$route.name == "Login" ||
        this.$route.name == "ForgotPassword" ||
        this.$route.name == "ResetPassword" ||
        this.$route.name == "ChangePassword" ||
        this.$route.name == "copydeal" 
        // ||
        // this.$route.name == 'ManageDealUpdateById' ||
        // this.$route.name == 'ManageDealUpdateCreate' ||
        // this.$route.name == 'VoyadoDashboard'
      ) {
        this.show_sidebar = false;
      }
      this.showFooter = true;
      this.changeRef = true;
    }
  },
  methods: {
    inIframe() {
      try {
        return window.self !== window.top;
      } catch (e) {
        return true;
      }
    },
    expand() {
      this.is_not_expand = !this.is_not_expand;
    },
    getClassName() {
    if (this.inIframe()) {
      this.show_sidebar = false;
      this.is_not_expand = false;
      return 'in-iframe';
    } else if (this.is_not_expand) {
      return 'not-expand';
    } 
    // else if (this.$route.name === 'ManageDealUpdateCreate' || this.$route.name === 'ManageDealUpdateById') {
    //   return 'min-expand';
    // } 
    else if (
      this.$route.name !== 'Login' && 
      this.$route.name !== 'ForgotPassword' && 
      this.$route.name !== 'ResetPassword' && 
      this.$route.name !== 'ChangePassword' && 
      this.$route.name !== 'copydeal'
    ) {
      return 'expand';
    }
    return '';
  },
  },
};
</script>
<style scoped>
/* .expand {
  margin-left: 210px;
  transition-timing-function: ease-in-out;
  transition-duration: 0.3s;
}
.not-expand {
  margin-left: 0px;
  transition-timing-function: ease-in-out;
  transition-duration: 0.3s;
} */

/* HeaderSideBarNew CSS */
.expand {
  margin-left: 90px;
  transition-timing-function: ease-in-out;
  transition-duration: 0.3s;
}
.not-expand {
  margin-left: 340px;
  transition-timing-function: ease-in-out;
  transition-duration: 0.3s;
}
.pinMenu {
  margin-left: 330px;
  transition-timing-function: ease-in-out;
  transition-duration: 0.3s;
}
.min-expand{
  margin-left: 0px;
  transition-timing-function: ease-in-out;
  transition-duration: 0.3s;
}
.in-iframe{
  margin-left: 0px;
  transition-timing-function: ease-in-out;
  transition-duration: 0.3s;
}
</style>
