<template>
  <div>
    <InputText
      :id="id"
      :value="modelValue"
      :disabled="disabled"
      :placeholder="placeholder"
      class="w-full"
      :class="{
        'p-invalid': validation?.$error || modelstate,
      }"
   
      :aria-describedby="`${id}-help`"
      @input="$emit('update:modelValue', $event.target.value)"
    />
    <span v-if="modelstate" class="p-error">
      {{ modelstate }}
    </span>
    <span
      v-if="
        validation?.$error &&
        validation.required &&
        validation.required.$invalid
      "
      :id="`${id}-help`"
      class="p-error text-left mt-2"
    >
      {{ validation.required.$message.replace("Value", PropName) }}
    </span>
    <span
    v-else-if="
        validation?.$error &&
        validation.requiredIf &&
        validation.requiredIf.$invalid
      "
      :id="`${id}-help`"
      class="p-error text-left mt-2"
    >
      {{ validation.requiredIf.$message.replace("value", PropName) }}
    </span>
    <span
      v-else-if="
        validation?.$error && validation.email && validation.email.$invalid
      "
      :id="`${id}-help`"
      class="p-error text-left mt-2"
    >
      {{ validation.email.$message }}
    </span>
    <span
      v-else-if="
        validation?.$error && validation.min && validation.min.$invalid
      "
      :id="`${id}-help`"
      class="p-error text-left mt-2"
    >
      {{ validation.min.$message }}
    </span>
    <span
      v-else-if="
        validation?.$error && validation.max && validation.max.$invalid
      "
      :id="`${id}-help`"
      class="p-error text-left mt-2"
    >
      {{ validation.max.$message }}
    </span>
    <span
      v-else-if="
        validation?.$error && validation.minLength && validation.minLength.$invalid
      "
      :id="`${id}-help`"
      class="p-error text-left mt-2"
    >
      {{ validation.minLength.$message }}
    </span>
    <span
      v-else-if="
        validation?.$error && validation.maxLength && validation.maxLength.$invalid
      "
      :id="`${id}-help`"
      class="p-error text-left mt-2"
    >
      {{ validation.maxLength.$message }}
    </span>
    <span
      v-else-if="
        validation?.$error && validation.sameAs && validation.sameAs.$invalid
      "
      :id="`${id}-help`"
      class="p-error text-left mt-2"
    >
      {{ validation.sameAs.$message }}
    </span>
    <span
      v-else-if="
        validation?.$error && validation.numeric && validation.numeric.$invalid
      "
      :id="`${id}-help`"
      class="p-error text-left mt-2"
    >
      {{ validation.numeric.$message }}
    </span>
    <span
      v-else-if="
        validation?.$error && validation.isValidLandingURL && validation.isValidLandingURL.$invalid
     "
      :id="`${id}-help`"
      class="p-error text-left mt-2"
    >
      The specified condition was not met for 'URL'.
    </span>
    
     <span
        v-else-if="!validation?.isValidLandingURL && IsLandingURL && validation.required"
      class="invalid-feedback"
      >The specified condition was not met for 'URL'.</span
    >
    <span   v-else-if="!validation?.isValidLandingURL && IsLandingURL" class="invalid-feedback"
      >The specified condition was not met for 'URL'.</span
    >
    <span
      v-else-if="
        validation?.$error && validation.isValidCustomCss && validation.isValidCustomCss.$invalid
     "
      :id="`${id}-help`"
      class="p-error text-left mt-2"
    >
     The value must be start with '# or .'
    </span>
    <span
      v-else-if="
        validation?.$error && validation.isValidImageUrl && validation.isValidImageUrl.$invalid
     "
      :id="`${id}-help`"
      class="p-error text-left mt-2"
    >
     The value must be start with 'http:// or https://.'
    </span>
    <!-- <span
      v-if="!v.isValidCustomFeildForRule && IsCustomFieldForRule"
      class="invalid-feedback"
      >The specified condition was not met for {{ PropName }} (The
      {{ PropName }} can't consist of email, phone_number, white space or special
      characters).</span
    >
    <span
      v-if="!v.isValidCustomTagForRule && IsCustomTagForRule"
      class="invalid-feedback"
      >The specified condition was not met for {{ PropName }} (The
      {{ PropName }} can't consist of white space or special characters).</span> -->
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: "",
    },
    PropName: {
      type: String,
      default: "",
    },
    validation: {
      type: Object,
      required: false,
    },
    modelstate: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    maxlength: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    mask: {
      type: String,
      default: "",
    },
    IsEmail: {
      type: Boolean,
      default: false,
    },
    IsLandingURL: {
      type: Boolean,
      default: false,
    },
    IsCustomFieldForRule: {
      type: Boolean,
      default: false,
    },
    IsCustomTagForRule: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  // computed: {
  //   name: {
  //     get() {
  //       return this.value;
  //     },
  //     set(value) {
  //       this.$emit("input", value);
  //     },
  //   },
  // },
};
</script>
<style lang="scss" scoped>

.p-inputtext:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none !important;
  border: #c0c0bc !important;
  outline: 2px solid #222222 !important;
  border-style: double !important;
}
</style>